import { Fragment, ReactNode, useCallback, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import cn from 'classnames'

export type ModalWrapperProps = {
  children: ReactNode
  open: boolean
  blurBackdrop?: boolean
  onClose?: () => void
  panelWrapperClass?: string
  panelClass?: string
  className?: string
  disableBackdropClick?: boolean
  autoFocus?: boolean
}

const ModalWrapper = (props: ModalWrapperProps) => {
  const { open, onClose, blurBackdrop, panelWrapperClass, panelClass, disableBackdropClick, className, autoFocus } =
    props
  const onBackdropClick = useCallback(() => {
    if (disableBackdropClick) return
    if (!onClose) return
    const targetElement = event?.target as Element
    if (targetElement?.closest('.Toastify')) {
      // prevent closing the modal when clicking on toastify notification
      return
    }
    return onClose()
  }, [onClose, disableBackdropClick])

  const hiddenFocusRef = useRef(null)

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className={cn('relative z-[105]', className)}
          onClose={onBackdropClick}
          initialFocus={autoFocus ? undefined : hiddenFocusRef}
        >
          <div className={cn('fixed inset-0 bg-black/60', blurBackdrop && 'backdrop-blur-md')} />
          <div className="hidden" ref={hiddenFocusRef} tabIndex={0} />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className={cn('flex h-full', panelWrapperClass)}>
                  <Dialog.Panel as="div" className={cn('relative h-full w-full transform transition-all', panelClass)}>
                    {props.children}
                  </Dialog.Panel>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ModalWrapper
