import { useCallback } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import classNames from 'classnames'
import { useBoolean } from 'usehooks-ts'

// @shared
import { Button } from '@shared/components/ui/button'
import { trackSegmentCustomEvent } from 'shared/services/analytics/segment'
import convertPathToPageName from 'shared/utils/convertPathToPageName'

const Search = dynamic(() => import('../Search'))
const MobileSearchbar = dynamic(() => import('shared/components/layout/Header/MobileSearchbar'))

const searchPlaceholder = `Try "Dreamy" artworks`

const generateSearchIcon = ({ isTransparent = false }: { isTransparent?: boolean }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="34" viewBox="0 0 32 34" fill="none">
      <path
        d="M14.6241 24.6379C19.1628 24.6379 22.842 20.9587 22.842 16.42C22.842 11.8814 19.1628 8.20215 14.6241 8.20215C10.0855 8.20215 6.40625 11.8814 6.40625 16.42C6.40625 20.9587 10.0855 24.6379 14.6241 24.6379Z"
        stroke={isTransparent ? 'white' : 'black'}
        strokeWidth="1.27186"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.8962 26.6911L20.4277 22.2227"
        stroke={isTransparent ? 'white' : 'black'}
        strokeWidth="1.27186"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const SearchIcon = ({ isTransparent = false }: { isTransparent?: boolean }) => generateSearchIcon({ isTransparent })

type LeftNavigationProps = {
  className?: string
  isTransparent?: boolean
  isSearchHidden?: boolean
  isLoggedIn?: boolean
  currentSearchText?: string
  onSearch?: (text: string) => void
}

// @ts-ignore
const LeftNavigation = ({
  className,
  isTransparent = false,
  isSearchHidden = false,
  isLoggedIn = false,
  currentSearchText,
  onSearch,
}: LeftNavigationProps) => {
  // router
  const { pathname } = useRouter()

  // states
  const {
    value: isMobileSearchbarOpen,
    setTrue: openMobileSearchbar,
    setFalse: closeMobileSearchbar,
  } = useBoolean(false)

  // search on mobile handler
  const handleOpenMobileSearchbar = useCallback(() => {
    openMobileSearchbar()
    trackSegmentCustomEvent('discover_search_icon_clicked', { screen: convertPathToPageName(pathname) })
  }, [openMobileSearchbar, pathname])

  return (
    <>
      {/* Desktop */}
      <div className={classNames('hidden flex-1 items-center justify-between lg:flex lg:justify-start', className)}>
        <nav className="flex items-center gap-4">
          {/* community button */}
          {isLoggedIn ? (
            <Button variant="outline" size="sm">
              <Link href="/discover" rel="noreferrer">
                <span>Discover</span>
              </Link>
            </Button>
          ) : (
            <></>
            // This will button will show, after done feed page
            // <Button variant="outline" size="sm" className="hidden">
            //   <Link href="/" rel="noreferrer">
            //     <span>Community</span>
            //   </Link>
            // </Button>
          )}

          {/* news button */}
          <Button variant="outline" size="sm">
            <Link href="/news" rel="noreferrer">
              <span>News</span>
            </Link>
          </Button>

          {/* search */}
          {!isSearchHidden && (
            <Search
              isTransparent={isTransparent}
              placeholder={searchPlaceholder}
              currentSearchText={currentSearchText}
              onSearch={onSearch}
              icon={<SearchIcon isTransparent={isTransparent} />}
            />
          )}
        </nav>
      </div>

      {/* Mobile */}
      {!isSearchHidden && (
        <div className={classNames('flex flex-1 items-center justify-between lg:hidden lg:justify-start', className)}>
          <nav className="flex items-center gap-4">
            {/* search */}
            <div className="lg:hidden" onClick={handleOpenMobileSearchbar}>
              <SearchIcon isTransparent={isTransparent} />
            </div>

            {isMobileSearchbarOpen && (
              <MobileSearchbar
                isOpen={isMobileSearchbarOpen}
                onClose={closeMobileSearchbar}
                placeholder={searchPlaceholder}
                currentSearchText={currentSearchText}
                onChange={onSearch}
              />
            )}
          </nav>
        </div>
      )}
    </>
  )
}

LeftNavigation.propTypes = {
  className: PropTypes.string,
  isTransparent: PropTypes.bool,
  isSearchHidden: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  currentSearchText: PropTypes.string,
  onSearch: PropTypes.func,
}

export default LeftNavigation
