import classNames from 'classnames'

// local
import localStyle from './index.module.scss'

type LandingPageBannerProps = {
  className?: string
  children?: any
}

const LandingPageBanner = ({ className, children }: LandingPageBannerProps) => {
  return <div className={classNames(localStyle.landingPageBanner, className)}>{children}</div>
}

export default LandingPageBanner
