import { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import Image from 'next/image'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { CurrencyDollarIcon } from '@heroicons/react/24/outline'

// @shared
import { Button } from '@shared/components/ui/button'
import useCheckingRole from 'shared/hooks/data-fetching/useCheckingRole'
import { useAskingRoleModals } from 'shared/hooks/useAskingRoleModals'

// local component
import MobileNavigation from '../MobileNavigation'
import MobileLoggedNavigation from '../MobileLoggedNavigation'

const UploadMenu = dynamic(() => import('shared/components/layout/Header/UploadMenu'))

// @ts-ignore
const RightNavigation = ({ className, isTransparent = false, isLoggedIn = false, profile }) => {
  const router = useRouter()

  // username
  const username = useMemo(() => profile?.username || '', [profile])

  // avatar
  const avatar = useMemo(() => profile?.coverImage || '/icons/default-ava.svg', [profile])

  // user role
  const {
    isUserHenry: isHenry,
    isUserCollector: isCollector,
    isUserIA: isIndependentAdvisor,
    isUserCreator: isCreator,
  } = useCheckingRole(profile?.role)

  // asking role modals
  const { showAreYouArtistModal, showApplyToBeCohartAdvisorModal } = useAskingRoleModals()

  // upload call to action handler
  const handleUploadCTAClick = useCallback(() => {
    if (isCollector) {
      showAreYouArtistModal()
    } else if (isIndependentAdvisor) {
      showApplyToBeCohartAdvisorModal()
    }
  }, [isCollector, isIndependentAdvisor, showAreYouArtistModal, showApplyToBeCohartAdvisorModal])

  // -- create artist invoice button render --
  const renderCreateArtistInvoiceButton = useMemo(() => {
    if (!isLoggedIn || !isCreator) return null
    return (
      <button
        className="flex w-max items-center gap-[6px] rounded-full border border-transparent bg-primary px-[6px] py-[5.5px] text-[11px] font-medium text-white transition-opacity hover:opacity-50 hover:shadow lg:px-3 lg:py-2 lg:text-sm"
        onClick={() => router.push('/artist/invoices/create')}
      >
        <CurrencyDollarIcon className="size-4 lg:size-5" />
        <span className="hidden lg:inline">Quick Sell</span>
      </button>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isCreator])

  // upload button render
  const renderUploadButton = useMemo(() => {
    if (!isLoggedIn) return null
    return <UploadMenu onCTAClick={handleUploadCTAClick} />
  }, [isLoggedIn, handleUploadCTAClick])

  return (
    <div className={classNames('flex flex-1 items-center justify-end gap-4', className)}>
      {(isHenry || !isLoggedIn) && (
        <>
          <nav className="hidden items-center gap-4 lg:flex">
            <Button variant="outline" size="sm">
              <Link href="/for-artists" rel="noreferrer">
                <span>For Artists</span>
              </Link>
            </Button>
            <Button variant="outline" size="sm">
              <Link href="/for-advisors" rel="noreferrer">
                <span>For Advisors</span>
              </Link>
            </Button>
          </nav>
          <div className="hidden h-[2rem] w-px bg-[#E3E3E3] lg:flex"></div>
        </>
      )}
      <nav className="flex items-center gap-4">
        {!isLoggedIn ? (
          <>
            <Button variant="outline" size="sm">
              <Link href="/auth/login" rel="noreferrer">
                <span>Login</span>
              </Link>
            </Button>
            <Button size="sm">
              <Link href="/auth/sign-up" rel="noreferrer">
                <span>Join</span>
              </Link>
            </Button>

            {/* show hamburger button on the mobile */}
            <MobileNavigation isTransparent={isTransparent} />
          </>
        ) : (
          <>
            {/* create artist invoice btn */}
            <div className="flex items-center space-y-2">{renderCreateArtistInvoiceButton}</div>

            {/* upload button */}
            <div className="flex items-center lg:px-4">{renderUploadButton}</div>

            {/* user information */}
            <div className="cursor-pointer">
              <Link href={`/${username}/profile`} className="hidden items-center gap-3 font-inter lg:flex">
                <p className="font-medium normal-case">@{username}</p>
                <div className="relative h-[48px] w-[48px] overflow-hidden rounded-full border border-black/10 ">
                  <Image src={avatar} fill className="object-cover" alt={username} />
                </div>
              </Link>
            </div>

            {/* show hamburger button on the mobile */}
            <MobileLoggedNavigation isTransparent={isTransparent} />
          </>
        )}
      </nav>
    </div>
  )
}

RightNavigation.propTypes = {
  className: PropTypes.string,
  isTransparent: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  profile: PropTypes.object,
}

export default RightNavigation
