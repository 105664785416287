// @shared
import { NewBackEndApiResponseData } from 'shared/types/ApiResponseData'

// @utils
import { apiClient } from 'utils/apiClient'
import { withApiErrorHandled } from 'utils/common'
import { withLogger } from 'utils/Logger'

const subscribeToNewsletter = async (requestBody: { firstName?: string; email: string }) => {
  const response = await withApiErrorHandled(apiClient.post)('/v2/public/newsletter/subscribe', requestBody)
  return response.data as NewBackEndApiResponseData
}

export default withLogger(subscribeToNewsletter, {
  action: 'subscribe_to_newsletter',
  customErrorText: 'Cannot subscribe to newsletter',
})
