import { trimStringProps } from 'shared/utils/string'
import { NewBackEndApiResponseData } from 'shared/types/ApiResponseData'
import { UserProfile } from 'shared/types/V3/User'
import { apiClient } from 'utils/apiClient'

const quickSignUp = async (args: { email: string; redirectUrl?: string; username?: string; role?: string }) => {
  const { email, redirectUrl, username, role } = args
  const submitData = {
    email: email.toLowerCase(),
    username: username?.toLowerCase(),
    role,
    redirectUrl,
  }

  try {
    const response = await apiClient.post('/v2/auth/quick-sign-up', trimStringProps(submitData, ['email']))
    const { ok, data, message } = response.data as NewBackEndApiResponseData<UserProfile>
    if (!ok) throw message
    return data
  } catch (error) {
    const errorMessage = typeof error === 'string' ? error : 'Something went wrong, please try again later'
    throw errorMessage
  }
}

export default quickSignUp
