import PropTypes from 'prop-types'
import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'

// @shared
import LandingPageSection from '@shared/components/LandingPageSection'
import ContentBox from '@shared/components/ContentBox'
import { Card } from '@shared/components/ui/card'
import { generateUserProfilePath } from '@shared/utils/url'

// @ts-ignore
const DailyInspirationSection = ({ className, title, subTitle, artistToWatch, categories }) => {
  return (
    <LandingPageSection className={classNames(className, 'lg:!w-full lg:!max-w-full')}>
      <ContentBox mainTitle={title} aboveTitle={subTitle}>
        {categories && <Categories categories={categories} artistToWatch={artistToWatch} />}
      </ContentBox>
    </LandingPageSection>
  )
}

DailyInspirationSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  artistToWatch: PropTypes.object,
  categories: PropTypes.array,
}

// @ts-ignore
const Categories = ({ className, artistToWatch, categories }) => {
  if (!categories?.length && !artistToWatch) return

  return (
    <div className={classNames('flex flex-col gap-[24px] pt-3 lg:flex-row', className)}>
      {/* artist to watch */}
      {artistToWatch && (
        <CategoryCard
          image={artistToWatch?.coverImage}
          subTitle={artistToWatch?.subTitle}
          title={artistToWatch?.fullName}
          link={generateUserProfilePath(artistToWatch?.username)}
        />
      )}

      {/* categories */}
      {categories?.length &&
        categories.map((category: any, index: number) => (
          <CategoryCard
            key={index}
            image={category?.thumbnail}
            subTitle={category?.subTitle}
            title={category?.title}
            link={category?.link}
          />
        ))}
    </div>
  )
}
Categories.propTypes = {
  className: PropTypes.string,
  artistToWatch: PropTypes.object,
  categories: PropTypes.array,
}

// @ts-ignore
const CategoryCard = ({ className, image, title, subTitle, link }) => {
  if (!image) return

  return (
    <Card className={classNames('rounded-2xl px-3 pb-3 pt-3 lg:w-1/3 lg:pb-6', className)}>
      <Link href={link || '/'}>
        {/* image */}
        <Image
          src={image}
          alt={title}
          width={429}
          height={429}
          className="h-[276px] w-full rounded-xl object-cover lg:h-[429px] lg:w-full lg:rounded-lg"
        />

        {/* content */}
        <div className="space-y-1 pt-6 text-sm">
          <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium uppercase leading-normal tracking-[0.7px] text-[#191414]">
            {subTitle}
          </p>
          <h3 className="overflow-hidden text-ellipsis whitespace-nowrap text-[2rem] font-bold leading-10 tracking-[-1.28px] text-[#1A1A1A]">
            {title}
          </h3>
        </div>
      </Link>
    </Card>
  )
}
CategoryCard.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  link: PropTypes.string,
}

export default DailyInspirationSection
