import { useCallback, useContext, useEffect, useMemo } from 'react'
import Router from 'next/router'
import useSWR from 'swr'
import getAuthUser from 'shared/services/me/getAuthUser'
import { authUserSwrKey } from 'constants/swrKeys'
import { UserProfile } from 'shared/types/V3/User'
import AuthContext from 'contexts/AuthContext'

const useAuth = ({ redirectTo = '', redirectIfFound = false } = {}) => {
  const { data: authUser, mutate: mutateAuth, isLoading: isFetching } = useSWR<UserProfile>(authUserSwrKey, getAuthUser)
  const isLoggedIn = authUser === undefined ? undefined : Boolean(authUser)
  // TODO: update redirectTo and redirectIfFound to be optional callback functions,
  // so we don't need import Router from 'next/router' here
  const destination = useMemo(() => {
    if (isFetching) return null // data is fetching
    if (isLoggedIn && redirectIfFound) return '/profile' // redirect to profile if user is logged in
    if (!isLoggedIn && redirectTo) return redirectTo // redirect if user is NOT logged in
    return null
  }, [isFetching, isLoggedIn, redirectIfFound, redirectTo])

  const checkIsCurrentUser = useCallback(
    (username: string) => {
      if (isFetching) return undefined // need to know if the data is fetching in some use cases
      return authUser?.username?.toLowerCase() === username?.toLowerCase()
    },
    [authUser?.username, isFetching],
  )

  useEffect(() => {
    destination && Router.push(destination)
  }, [destination])

  const {
    showAuthModal,
    setAuthMode,
    setUsername,
    closeAuthModal,
    setSignUpAsRole,
    setSignUpMetadata,
    handleSuccess,
    setMarketingMessage,
    setCustomizedSignUpTitle,
    isUserSegmentationPopupVisible,
    shouldRedirectAfterSegmentation,
    handleUserSegmentationPopup,
  } = useContext(AuthContext)

  return {
    isLoggedIn,
    mutateAuth,
    isFetching,
    authUser,
    checkIsCurrentUser,
    showAuthModal,
    setAuthMode,
    setUsername,
    closeAuthModal,
    setSignUpAsRole,
    setSignUpMetadata,
    handleSuccess,
    setMarketingMessage,
    setCustomizedSignUpTitle,
    isUserSegmentationPopupVisible,
    shouldRedirectAfterSegmentation,
    handleUserSegmentationPopup,
  }
}

export default useAuth
