import PropTypes from 'prop-types'
import classNames from 'classnames'

// @shared
import { Heading } from '@shared/components/Heading'
import { Text } from '@shared/components/Text'

type ContentBoxProps = {
  mainTitle?: string
  mainTitleClassName?: string
  aboveTitle?: string
  description?: string
  descriptionClassName?: string
  className?: string
  children?: any
}

const ContentBox = ({
  mainTitle,
  mainTitleClassName,
  aboveTitle,
  description,
  descriptionClassName,
  children,
  className,
}: ContentBoxProps) => {
  return (
    <>
      {/* title & description */}
      <div className={classNames('flex flex-col items-center pb-6', className)}>
        <div className="text-center lg:w-[55vw]">
          {/* above title */}
          {aboveTitle && (
            <Text
              size="2xl"
              className="pb-2.5 text-base font-normal leading-[1.125rem] text-[#191414] lg:pb-4 lg:text-lg lg:leading-5"
            >
              {aboveTitle}
            </Text>
          )}

          {/* main title */}
          {mainTitle && (
            <Heading className={classNames('pb-2 text-center leading-none', mainTitleClassName)} size="h1" element="h2">
              {mainTitle}
            </Heading>
          )}

          {/* description */}
          {description && (
            <Text
              size="2xl"
              className={classNames(
                'text-sm	font-normal leading-4 text-[#1A1A1A] lg:text-2xl lg:leading-[1.875rem]',
                descriptionClassName,
              )}
            >
              {description}
            </Text>
          )}
        </div>
      </div>

      {/* any context */}
      {children}
    </>
  )
}

ContentBox.propTypes = {
  className: PropTypes.string,
  mainTitleClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  mainTitle: PropTypes.string,
  aboveTitle: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
}

export default ContentBox
