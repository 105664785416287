import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import HeaderSearchBar from 'shared/components/layout/Header/HeaderSearchBar'

// @shared
import { Heading } from '@shared/components/Heading'
import { Button } from '@shared/components/ui/button'
import LandingPageBanner from '@shared/components/LandingPageBanner'

// local
import searchStyle from './index.module.scss'

const SEARCH_TAGS = [
  {
    text: 'Dreamy Pastel Paintings',
  },
  {
    text: 'Photography',
  },
  {
    text: 'Modern Abstracts',
  },
  {
    text: 'Art for Living Room',
  },
  {
    text: 'Vintage',
  },
  {
    text: 'Sculptures for Gardens',
  },
  {
    text: 'Bold & Colorful',
  },
  {
    text: 'Nature-Inspired',
  },
  {
    text: 'Office Wall Art',
  },
]

// @ts-ignore
const Search = ({ className }) => {
  // -- router --
  const router = useRouter()

  return (
    <LandingPageBanner className={classNames(searchStyle.searchSection, className)}>
      <div className="flex flex-col items-center justify-center px-[1rem]">
        {/* title */}
        <Heading
          size="h1"
          className="text-[36px] font-semibold leading-[100%] tracking-[-1.92px] text-white lg:text-[96px]"
        >
          Search Anything
        </Heading>

        {/* search input */}
        <div className="mt-[40px] w-full max-w-[400px]">
          <HeaderSearchBar
            className="!w-full !min-w-[100px]"
            wrapperClassName="!flex"
            popoverPanelClassName="!min-w-[100px]"
            screen="homepage"
            placeholder={`Try "Dreamy" artworks`}
            isTransparent={false}
          />
        </div>

        {/* search tags */}
        <div className="mt-[40px] flex max-w-[770px] flex-wrap items-center justify-center gap-[12px]">
          {SEARCH_TAGS.map((searchTag: any) => (
            <Button
              className="bg-transparent font-medium text-white"
              size="sm"
              variant="outline"
              onClick={() => router.push(`/discover/artworks?search=${encodeURIComponent(searchTag.text)}`)}
            >
              {searchTag.text}
            </Button>
          ))}
        </div>
      </div>
    </LandingPageBanner>
  )
}

Search.propTypes = {
  className: PropTypes.string,
}

export default Search
