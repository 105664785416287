import cn from 'classnames'
import Button from 'shared/components/button/Button'
import RoundedAvatarImage from 'shared/components/avatar/RoundedAvatarImage'
import CustomImage from 'shared/components/CustomImage'
import InputErrorText from 'shared/components/input/InputErrorText'
import useAuth from 'hooks/authentication/useAuth'
import { PATTERN } from 'configs/validation'
import GCaptchaProvider, { useGCaptchaVerify } from 'contexts/GCaptchaProvider'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { trackSegmentCustomEvent } from 'shared/services/analytics/segment'
import { InvitationInfo } from 'shared/types/Auth'
import { ArtworkPreview } from 'shared/types/V3/Artwork'
import { useBoolean, useEffectOnce } from 'usehooks-ts'
import useAuthType from 'views/authentication/hooks/useAuthType'
import signUpWithEmailAndPassword from 'views/authentication/services/signUpWithEmailAndPassword'
import confirmInvitation from '../services/confirmInvitation'
import Router, { useRouter } from 'next/router'
import logInWithEmailAndPassword from '../services/logInWithEmailAndPassword'
import swapCohartToken from '../services/swapCohartToken'
import loginUser from '../services/loginUser'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import NameWithVerificationBadge from 'shared/components/display/NameWithVerificationBadge'
import { sendHubspotSignUpFormAPI } from '../util'
import { WEB_BASE_URL } from 'configs'
import CohartLogo from './Logo'
// import EmailVerificationView from './EmailVerificationView'

type SignUpFormInputs = {
  firstName: string
  lastName: string
  password: string
  confirmPassword: string
}

type CollectorSignUpProps = {
  mode?: 'page' | 'modal'
  artwork?: ArtworkPreview | null // a collector can be invited to be a collector of an artwork (upload/edit artwork flow),
  // or just a collector (using invite option in the menu)
  invitationInfo: InvitationInfo
  invitationToken?: string
  redirectTo?: string
  subtext?: string
  onSuccess?: () => void
}

const CollectorSignUp = (props: CollectorSignUpProps) => {
  const { artwork, invitationInfo, invitationToken, redirectTo, mode = 'page', subtext, onSuccess } = props
  const router = useRouter()
  const { value: isPasswordVisible, toggle: togglePasswordVisible } = useBoolean(false)
  const { isCaptchaReady, handleReCaptchaVerify } = useGCaptchaVerify()
  const { email: signUpEmail } = invitationInfo

  // auth
  const { closeAuthModal } = useAuth()

  // use this to check if we should show the OTP verification screen
  // const [step, setStep] = useState<'sign-up' | 'verify-email'>('sign-up')

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignUpFormInputs>({
    defaultValues: {
      firstName: invitationInfo.firstName,
      lastName: invitationInfo.lastName,
    },
  })

  const handleAuthenticateSuccess = async (userId: number, firstName: string, signUpDate: Date) => {
    trackSegmentCustomEvent('user_signed_up_successfully', {
      sign_up_date: signUpDate,
      sign_up_method: authType === 'password' ? 'email' : authType,
      user_role: 'collector',
      email: signUpEmail,
      user_id: userId,
      first_name: firstName,
    })

    const shouldWelcome = Boolean(invitationToken)
    // store this info in the local storage, so that we can use it later
    localStorage.setItem('shouldWelcome', shouldWelcome ? 'true' : 'false')

    // confirm invitation if any
    if (invitationToken) {
      await confirmInvitation(invitationToken)
    }

    if (onSuccess) {
      return onSuccess()
    }

    if (mode === 'modal') {
      if (redirectTo) await router.push(redirectTo || '/homepage')
      closeAuthModal()
      return
    }

    // if user sign up using the sign-up page, redirect to homepage if there's no destination
    await Router.push(redirectTo || '/homepage')
  }

  const [authType, setAuthType] = useAuthType()
  const [isRequesting, setIsRequesting] = useState(false)

  const submitSignUpForm = async (data: SignUpFormInputs) => {
    if (isRequesting) return // auth is in progress

    await handleReCaptchaVerify('signup_user')
    setAuthType('password')
    setIsRequesting(true)
    try {
      // send the Hubspot sign up Form API, for tracking purpose
      await sendHubspotSignUpFormAPI(data.firstName, signUpEmail, `https://${WEB_BASE_URL}${router.asPath}`)
      await signUpWithEmailAndPassword({
        email: signUpEmail,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        isArtist: false,
        asRole: 'collector',
      })

      // sign up successful, log in the user
      const userCredential = await logInWithEmailAndPassword(signUpEmail, data.password)

      // swap cohart token (stored in cookie) by firebase credential
      const { user } = await swapCohartToken(userCredential)

      // mark user as logged in
      await loginUser(user)

      // user hasn't signed up yet, he still needs to verify his email
      // verify email step
      // setStep('verify-email')

      await handleAuthenticateSuccess(user.id, user.firstName, user.createdAt)
    } catch (error) {
      console.error(error)
      const message = typeof error === 'string' ? error : 'Cannot sign up. Please try again.'
      setError('password', { message })
    } finally {
      setIsRequesting(false)
    }
  }

  useEffectOnce(() => {
    trackSegmentCustomEvent('user_sign_up_started')
  })

  const title = artwork ? 'Join Cohart to Be Featured as the Buyer of This Artwork' : 'Join as a Buyer'

  // verify otp step
  // if (step === 'verify-email' && signUpEmail) {
  //   return (
  //     <EmailVerificationView
  //       email={signUpEmail}
  //       redirectTo={redirectTo}
  //       invitationToken={invitationToken}
  //       mode="page"
  //     />
  //   )
  // }

  // sign-up step
  return (
    <div
      className={cn(
        'flex w-full items-center gap-5 lg:w-[404px] lg:gap-12',
        artwork && 'flex-col lg:!w-[800px] lg:flex-row',
      )}
    >
      {/* artwork preview */}
      {artwork && (
        <div className="relative flex h-full w-full items-center gap-5 lg:flex-col lg:items-start">
          <div className="relative aspect-[170/211] w-[50%] shrink-0 overflow-hidden border border-black/10 bg-[#E9E9E9] lg:w-full">
            <CustomImage
              src={artwork.imageMedium}
              fallbackSrc="/images/default.png"
              className="object-contain object-center"
              fill
              alt={artwork.title}
              sizes="(max-width: 768px) 50vw, (max-width: 1200px) 33vw, (max-width: 1920px) 25vw, 10vw"
            />
          </div>

          <div className="w-full space-y-1">
            <div className="text-[10px] font-semibold">{artwork.title}</div>

            <div className="flex items-center gap-1">
              {artwork.creator?.coverImage && (
                <RoundedAvatarImage src={artwork.creator?.coverImage} className="h-4 w-4" />
              )}
              <NameWithVerificationBadge
                fullName={artwork.creator?.fullName || ''}
                className="text-[8px]"
                isVerified={artwork.creator?.isVerified || false}
                maxNameLength={30}
              />
            </div>
          </div>
        </div>
      )}

      {/* sign up form */}
      <div className="relative flex h-full w-full flex-col items-center justify-evenly gap-4 lg:gap-6">
        {/* title */}
        <h2 className="!mt-0 text-center font-monument-grotes text-[20px] font-medium leading-none lg:text-2xl">
          {title}
        </h2>

        {/* subtext */}
        {subtext && <p className="text-center">{subtext}</p>}

        {!artwork && !subtext && (
          <p className="text-center">
            Join now to discover original pieces, connect with artists, and start your art journey on Cohart
          </p>
        )}

        {/* sign-up form */}
        <form onSubmit={handleSubmit(submitSignUpForm)} className="w-full space-y-10">
          <div className="mb-6 space-y-[15px]">
            {/* First Name Field */}
            <div className="">
              <div className="col-span-10 w-full rounded-[8px] border border-black/10 px-4 py-2 lg:py-3">
                <input
                  className="w-full border-transparent p-0 text-xs placeholder:text-black/20 focus:border-transparent focus:ring-0 lg:text-base"
                  type="text"
                  placeholder="First Name"
                  {...register('firstName', {
                    required: {
                      value: true,
                      message: 'First name is required',
                    },
                    maxLength: { value: 30, message: 'First name must be less than 30 characters' },
                    validate: {
                      nonSpaceValue: (value) => !!value.trim() || 'First name is required',
                    },
                  })}
                />
              </div>
              <InputErrorText message={errors.firstName?.message} />
            </div>

            {/* Last Name Field */}
            <div className="">
              <div className="col-span-10 w-full rounded-[8px] border border-black/10 px-4 py-2 lg:py-3">
                <input
                  className="w-full border-transparent p-0 text-xs placeholder:text-black/20 focus:border-transparent focus:ring-0 lg:text-base"
                  type="text"
                  placeholder="Last Name"
                  defaultValue={invitationInfo.firstName}
                  {...register('lastName', {
                    maxLength: { value: 30, message: 'Last name must be less than 30 characters' },
                    validate: {
                      nonSpaceValue: (value) => !!value.trim() || 'Last name is required',
                    },
                  })}
                />
              </div>
              <InputErrorText message={errors.lastName?.message} />
            </div>

            {/* Password Field */}
            <div className="">
              <div className="flex w-full flex-row gap-2">
                <div className="col-span-10 flex w-full items-center rounded-[8px] border border-black/10 px-4 py-2 lg:py-3">
                  <input
                    className="w-full border-transparent p-0 text-xs placeholder:text-black/20 focus:border-transparent focus:ring-0 lg:text-base"
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    autoComplete={isPasswordVisible ? 'off' : 'on'}
                    {...register('password', {
                      required: { value: true, message: 'Password is required' },
                      minLength: { value: 6, message: 'Password is too short! Minimum 6 characters' },
                      pattern: {
                        value: PATTERN.password,
                        message: "Your password can't start or end with a blank space",
                      },
                    })}
                  />
                  <div className="cursor-pointer" onClick={togglePasswordVisible}>
                    {isPasswordVisible ? (
                      <EyeIcon className="size-6 text-[#1914144D]" />
                    ) : (
                      <EyeSlashIcon className="size-6 text-[#1914144D]" />
                    )}
                  </div>
                </div>
              </div>
              <InputErrorText message={errors.password?.message} />
            </div>
          </div>

          <Button
            type="submit"
            name={artwork ? 'Sign Up and Get Featured' : 'Create Your Buyer Profile'}
            wrapperClass="shadow-image w-full rounded-full lg:py-4 lg:px-[16px] !bg-[#0F6BFF] border border-black/10 bg-mint-green"
            contentClass="!text-sm lg:!text-base font-semibold !text-white normal-case"
            disabled={!isCaptchaReady}
            loading={isRequesting && authType === 'password'}
          />
        </form>

        <div className="w-full text-center text-xs text-[#898788]">
          By signing up, you agree to our&nbsp;
          <a className="font-bold text-[#0F6BFF] hover:underline" href="/terms-of-service" target="_blank">
            Terms & Conditions
          </a>
        </div>

        <CohartLogo />
      </div>
    </div>
  )
}

const CollectorSignUpWithGCaptcha = (props: CollectorSignUpProps) => {
  return (
    <GCaptchaProvider>
      <CollectorSignUp {...props} />
    </GCaptchaProvider>
  )
}

CollectorSignUpWithGCaptcha.displayName = 'CollectorSignUp'
export default CollectorSignUpWithGCaptcha
