import PropTypes from 'prop-types'

// @shared
import Metadata from 'shared/components/Metadata'
import Header from '@shared/components/layout/Header'
import FooterSection from 'shared/components/layout/FooterSection'

// @ts-ignore
const PublicLayout = ({ children, isTransparent = false, isSearchHidden = false, footerClassName }) => {
  return (
    <>
      {/* metadata */}
      <Metadata />

      {/* header */}
      <Header isTransparent={isTransparent} isSearchHidden={isSearchHidden} />

      {/* main */}
      {children && children}

      {/* footer */}
      <FooterSection className={footerClassName} curveColor="transparent" />
    </>
  )
}

PublicLayout.propTypes = {
  children: PropTypes.any,
  isTransparent: PropTypes.bool,
  isSearchHidden: PropTypes.bool,
  footerClassName: PropTypes.string,
}

export default PublicLayout
