export const cohartExternalLinks = {
  // cohart support
  support: `https://support.cohart.com`,
  supportForHenryUser: `https://support.cohart.com/getting-started`,
  supportForCollectorUser: `https://support.cohart.com/for-collectors`,
  supportForCreatorUser: `https://support.cohart.com/for-artists`,
  supportForAdvisorUser: `https://support.cohart.com/for-advisors`,
  createAProfile: 'https://support.cohart.com/create-a-profile',
  verificationGuidelines: 'https://support.cohart.com/verification',
  uploadingMomentGuidelines: 'https://support.cohart.com/share-and-engage-with-moments',
  communityGuidelines: 'https://support.cohart.com/community-guidelines',
  shippingGuidelines: 'https://support.cohart.com/shipping-guidelines',
  pricingGuidelines: 'https://support.cohart.com/pricing-guidelines',
  uploadingGuidelines: 'https://support.cohart.com/uploading-guidelines',
  howToSellGuidelines: 'https://support.cohart.com/faqs-how-do-i-sell-artwork-on-cohart',

  // faqs
  faqs: 'https://support.cohart.com/for-artists',

  // artists page
  artistsPage: 'https://web.cohart.com/artists',

  // others
  careers: 'https://www.notion.so/cohart/Work-with-Us-42cfe1b429c547648fa5dda5fbb76a9a',
  tiktok: 'https://www.tiktok.com/@joincohart',
  instagram: 'https://www.instagram.com/joincohart',
  twitter: 'https://www.twitter.com/joincohart',
  linkedIn: 'https://www.linkedin.com/company/joincohart',
}
