import { HomeIcon, ShoppingBagIcon, UserCircleIcon, LightBulbIcon } from '@heroicons/react/24/outline'
import useAuth from 'hooks/authentication/useAuth'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo } from 'react'
import SideBarPopover from 'shared/components/popover/SideBarPopover'
import {
  AdvisorMenu,
  CollectorMenu,
  CreatorMenu,
  HenryMenu,
  MenuItemHrefType,
  SharedMenu,
} from 'shared/contexts/AppLayoutContext/configs'
import useCheckingRole from 'shared/hooks/data-fetching/useCheckingRole'
import useAppLayout from 'shared/hooks/useAppLayout'
import { PageItem, PageItemGroup } from './PageItemGroup'
import { cohartExternalLinks } from 'configs/cohartExternalLinks'

type SideBarProps = {
  onDesktopSidebarReady?: (isReady: boolean) => void // notify the parent component that the desktop sidebar is ready
  isDesktopSidebar: boolean // indicates if the sidebar is a desktop sidebar, so we don't block scrolling when the sidebar is visible
}

const SideBar = (props: SideBarProps) => {
  const { onDesktopSidebarReady, isDesktopSidebar } = props
  const { pathname, query, asPath } = useRouter()
  const {
    toggleMobileSidebar,
    appLayout: { isMobileSidebarVisible },
  } = useAppLayout()
  const { authUser } = useAuth()

  const { isUserCollector, isRoleMapReady, isUserHenry, isUserCreator, isUserIA, isUserCA } = useCheckingRole(
    authUser?.role,
  )
  const isOnUserProfilePage = pathname.includes('/[username]/profile') // consider sub-profile pages as well (e.g. /[username]/moodboards, artworks, moments, ...)
  const isOnEditProfilePage = pathname.includes('/[username]/edit')
  const isOnProfileFoldersTab = pathname === '/[username]/moodboards' // check if the user is on the profile folders tab
  const isOnCurrentProfilePage = isOnUserProfilePage && authUser?.username === query.username
  const isDashboardHomepage = pathname === '/homepage'
  const isPurchasesPage = pathname === '/purchases'

  const activeHref = useMemo(() => {
    if (isDashboardHomepage) return '/homepage'
    if (isOnCurrentProfilePage && !isOnProfileFoldersTab) return '/[username]/profile'
    if (isOnEditProfilePage) return '/[username]/edit'
    if (isPurchasesPage) return '/purchases'

    const menuItems = [
      ...(isUserHenry ? HenryMenu : []),
      ...(isUserCollector ? CollectorMenu : []),
      ...(isUserCreator ? CreatorMenu : []),
      ...(isUserCA || isUserIA ? AdvisorMenu : []),
      ...SharedMenu,
    ]

    let activeHref: MenuItemHrefType | null = null

    for (let i = 0; i < menuItems.length; i++) {
      const { regex, subMenu } = menuItems[i]
      // check for the item with sub menu
      if (subMenu) {
        const item = subMenu.find((subItem) => {
          if (!subItem.regex) return false
          return typeof subItem.regex === 'function'
            ? subItem.regex(authUser?.username).test(asPath)
            : subItem.regex.test(asPath)
        })
        activeHref = item?.href || null
      } else {
        // check for the item without sub menu
        if (!regex) return null
        if (typeof regex === 'function' ? regex(authUser?.username).test(asPath) : regex.test(asPath)) {
          activeHref = menuItems[i].href || null
          break
        }
      }
    }

    return activeHref
  }, [
    isPurchasesPage,
    isOnCurrentProfilePage,
    isOnProfileFoldersTab,
    isOnEditProfilePage,
    isUserHenry,
    isUserCollector,
    isUserCreator,
    isUserCA,
    isUserIA,
    authUser?.username,
    asPath,
    isDashboardHomepage,
  ])

  const supportLink = useMemo(() => {
    if (isUserCreator) return cohartExternalLinks.supportForCreatorUser
    if (isUserHenry) return cohartExternalLinks.supportForHenryUser
    if (isUserCollector) return cohartExternalLinks.supportForCollectorUser
    if (isUserCA || isUserIA) return cohartExternalLinks.supportForAdvisorUser
    return cohartExternalLinks.support
  }, [isUserHenry, isUserCollector, isUserCreator, isUserCA, isUserIA])

  // use this value to determine if we should show the desktop sidebar or not
  const isDesktopSidebarReady = Boolean(
    authUser && // currently sidebar is only available for authenticated users
      isRoleMapReady && // only show sidebar when the role map is fetched
      !(isOnUserProfilePage && !isOnCurrentProfilePage), // don't show sidebar when viewing other user's profile
  )

  useEffect(() => {
    // notify the parent component that the sidebar is ready
    onDesktopSidebarReady && onDesktopSidebarReady(isDesktopSidebarReady)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktopSidebarReady])

  const createMenuItemHref = useCallback(
    (href: string | ((username?: string) => string) | undefined) => {
      if (!href) return ''
      return typeof href === 'string' ? href : href(authUser?.username)
    },
    [authUser?.username],
  )

  const handleMenuItemClick = useCallback(() => {
    if (isDesktopSidebar) return // dont trigger the mobile sidebar if it's a desktop sidebar
    // close the mobile sidebar when the user clicks on the menu
    toggleMobileSidebar()
  }, [isDesktopSidebar, toggleMobileSidebar])

  if (!isDesktopSidebarReady) return null

  return (
    <div className="relative flex h-full w-full flex-col  bg-white px-4 py-3 lg:justify-between">
      <div className={'no-scrollbar flex h-full w-full flex-col overflow-scroll pb-[150px] lg:pb-0'}>
        <div className="w-full space-y-1 border-b border-black/10 py-1 lg:space-y-2 lg:py-2">
          <PageItem
            isActive={activeHref === '/homepage'}
            label="Home"
            hrefLink={createMenuItemHref('/homepage')}
            icon={<HomeIcon className="h-6 w-6" />}
            onClick={handleMenuItemClick}
          />

          <PageItem
            isActive={isMobileSidebarVisible ? activeHref === '/[username]/profile' : activeHref === '/[username]/edit'}
            label={isMobileSidebarVisible ? 'Public Profile' : 'Edit Profile'}
            hrefLink={createMenuItemHref(
              isMobileSidebarVisible ? `/${authUser?.username}/profile` : `/${authUser?.username}/edit`,
            )}
            icon={<UserCircleIcon className="h-6 w-6" />}
            onClick={handleMenuItemClick}
          />
        </div>

        <div className="flex w-full flex-col gap-1 border-b border-black/10 pb-1 pt-2 lg:gap-2 lg:pb-2 lg:pt-4">
          {CreatorMenu.map((menu, index) => (
            <PageItemGroup
              key={index}
              activeHref={activeHref}
              label={menu.label}
              href={menu.href}
              createMenuItemHref={createMenuItemHref}
              icon={menu.icon}
              onClick={handleMenuItemClick}
              subMenu={menu.subMenu}
            />
          ))}
        </div>

        {/* shared */}
        <div className="flex w-full flex-1 flex-col gap-1 py-1 lg:gap-2 lg:py-2">
          {/* show purchases here for collector and advisor */}
          <PageItem
            isActive={activeHref === '/purchases'}
            label={'Purchases'}
            hrefLink={createMenuItemHref('/purchases')}
            icon={<ShoppingBagIcon className="h-6 w-6" />}
            onClick={handleMenuItemClick}
          />
          {SharedMenu.map((menu, index) => {
            return (
              <PageItem
                key={index}
                isActive={activeHref === menu.href}
                label={menu.label}
                hrefLink={createMenuItemHref(menu.href)}
                icon={menu.icon}
                onClick={handleMenuItemClick}
              />
            )
          })}

          <div className="flex flex-col lg:flex-1 lg:justify-end lg:pb-[50px]">
            <PageItem
              isActive={false}
              label="Knowledge Base"
              hrefLink={supportLink}
              icon={<LightBulbIcon className="h-6 w-6" />}
              target="_blank"
            />
          </div>

          {/* Side Bar Popover for mobile */}
          <div className="block w-full border-t border-black/10 pt-1 lg:hidden lg:pt-2">
            <SideBarPopover isDesktopSidebar={isDesktopSidebar} isUserCreator={isUserCreator} />
          </div>
        </div>
      </div>

      {/* Side Bar Popover for desktop */}
      <div className="absolute bottom-3 left-0 hidden w-full bg-white lg:block">
        <div className="w-full border-t border-black/10 pt-1 lg:pt-2">
          <SideBarPopover isDesktopSidebar={isDesktopSidebar} isUserCreator={isUserCreator} />
        </div>
      </div>
    </div>
  )
}

export default SideBar
