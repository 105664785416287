import { useEffect } from 'react'
import { notifyError } from 'utils/toast'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { LinkToCohartSupportEmail } from 'configs/contact'
import resendQuickSignupEmail from 'views/authentication/services/resendQuickSignupEmail'
import useCountdownTimer from 'hooks/useCountdownTimer'

type QuickSignUpResendProps = {
  email: string
  redirectUrl?: string
}

const QuickSignUpResend = (props: QuickSignUpResendProps) => {
  const { email, redirectUrl } = props
  const { timeRemaining, startTimer } = useCountdownTimer()
  useEffect(() => {
    startTimer(59)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResend = async () => {
    try {
      await resendQuickSignupEmail({ email, redirectUrl })
      startTimer(59)
    } catch (error) {
      // @ts-ignore
      notifyError(error.message ?? 'Server error')
    }
  }

  return (
    <div className="box-border flex h-full w-[80vw] flex-col items-center space-y-[30px] md:w-[404px] ">
      <CheckCircleIcon className="size-20 text-[#00D973]" />

      {/* title */}
      <h2 className="text-center text-[24px]">Check Your Email to Continue!</h2>

      <p className="text-center text-[16px] text-[#191414]">
        We’ve sent you a secure magic link to activate your account. Open your email, click the link, and you’ll be all
        set to explore Cohart!
      </p>

      <div className="flex-col items-center space-y-[24px]">
        <p className="text-center text-[14px] text-[#191414]">
          Didn’t receive the link?
          {timeRemaining > 0 ? (
            ` Resend in 00:${timeRemaining.toString().padStart(2, '0')}`
          ) : (
            <span className="cursor-pointer font-semibold text-primary hover:underline" onClick={() => handleResend()}>
              {' '}
              Resend
            </span>
          )}
        </p>

        <div className="w-full text-center text-xs text-[#898788]">
          Need help?&nbsp;
          <a className="font-semibold text-primary hover:underline" href={LinkToCohartSupportEmail}>
            Contact Support
          </a>
        </div>
      </div>
    </div>
  )
}

export default QuickSignUpResend
