import { useState } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import classNames from 'classnames'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

// local
import navStyle from './index.module.scss'

// @ts-ignore
const MobileNavigation = ({ className, isTransparent = false }) => {
  // states
  const [isNavOpen, setIsNavOpen] = useState(false)

  return (
    <nav className={classNames('flex lg:hidden', className)}>
      <div className="space-y-2" onClick={() => setIsNavOpen((prev) => !prev)}>
        <Bars3Icon width={32} height={32} className={classNames(isTransparent ? 'text-white' : 'text-[#1A1A1A]')} />
      </div>

      <div className={classNames(isNavOpen ? navStyle.mobileNavShow : navStyle.mobileNavHide)}>
        <div className="absolute right-0 top-0 px-8 py-8" onClick={() => setIsNavOpen(false)}>
          <XMarkIcon width={32} height={32} />
        </div>
        <ul className="flex min-h-[250px] flex-col items-center justify-between">
          <li className="my-8 border-b border-gray-400 uppercase">
            <Link href="/auth/login" rel="noreferrer">
              <span>Login</span>
            </Link>
          </li>
          <li className="my-8 border-b border-gray-400 uppercase">
            <Link href="/auth/sign-up" rel="noreferrer">
              <span>Join</span>
            </Link>
          </li>
          <li className="my-8 border-b border-gray-400 uppercase">
            <Link href="/" rel="noreferrer">
              <span>Community</span>
            </Link>
          </li>
          <li className="my-8 border-b border-gray-400 uppercase">
            <Link href="/news" rel="noreferrer">
              <span>News</span>
            </Link>
          </li>
          <li className="my-8 border-b border-gray-400 uppercase">
            <Link href="/for-artists" rel="noreferrer">
              <span>For Artists</span>
            </Link>
          </li>
          <li className="my-8 border-b border-gray-400 uppercase">
            <Link href="/for-advisors" rel="noreferrer">
              <span>For Advisors</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}

MobileNavigation.propTypes = {
  className: PropTypes.string,
  isTransparent: PropTypes.bool,
}

export default MobileNavigation
