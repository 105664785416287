import { useCallback } from 'react'
import { trackSegmentCustomEvent } from 'shared/services/analytics/segment'

export const useTrackingDownloadIOSClicked = (view: string) => {
  const trackDownloadIOSClicked = useCallback(() => {
    trackSegmentCustomEvent('download_ios_clicked', { view })
  }, [view])

  return trackDownloadIOSClicked
}
