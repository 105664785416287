import PropTypes from 'prop-types'
import classNames from 'classnames'
import useAppLayout from 'shared/hooks/useAppLayout'

// @ts-ignore
const MobileLoggedNavigation = ({ className, isTransparent = false }) => {
  // toggle mobile logged sidebar
  const { toggleMobileSidebar, appLayout } = useAppLayout()

  return (
    <div
      role="button"
      onClick={toggleMobileSidebar}
      className={classNames(
        'relative size-6 focus:outline-none lg:hidden',
        isTransparent ? 'text-white' : 'text-[#1A1A1A]',
        className,
      )}
    >
      <div className="absolute left-1/2 top-1/2 block w-6 -translate-x-1/2 -translate-y-1/2 scale-75 transform">
        <span
          aria-hidden="true"
          className={classNames(
            'absolute block h-[1px] w-6 transform bg-current transition duration-300 ease-in-out',
            appLayout.isMobileSidebarVisible ? 'rotate-45' : '-translate-y-2.5',
          )}
        />
        <span
          aria-hidden="true"
          className={classNames(
            'absolute block h-[1px] w-6 transform bg-current transition duration-300 ease-in-out',
            appLayout.isMobileSidebarVisible ? 'opacity-0' : '',
          )}
        />
        <span
          aria-hidden="true"
          className={classNames(
            'absolute block h-[1px] w-6 transform bg-current transition duration-300 ease-in-out',
            appLayout.isMobileSidebarVisible ? '-rotate-45' : 'translate-y-2.5',
          )}
        />
      </div>
    </div>
  )
}

MobileLoggedNavigation.propTypes = {
  className: PropTypes.string,
  isTransparent: PropTypes.bool,
}

export default MobileLoggedNavigation
