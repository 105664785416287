import { withApiErrorHandled } from 'utils/common'
import { apiClientSsr } from 'utils/apiClient'
import { getDailyInspirationSwrKey } from 'constants/swrKeys'
import { NewBackEndApiResponseData } from 'shared/types/ApiResponseData'

// @shared
import PublicLayout from '@shared/components/layout/PublicLayout'
import { dateFormat } from '@shared/utils/datetime'

// public pages
import Homepage, { type DailyInspiration } from '@src/pages/public/Homepage/Homepage'

const getDailyInspiration = async () => {
  const swrKey = getDailyInspirationSwrKey()
  const response = await withApiErrorHandled(apiClientSsr.get)(swrKey)
  const { data } = response.data as NewBackEndApiResponseData<any>
  const artistToWatch = data?.artistToWatch ? { ...data.artistToWatch, subTitle: 'Artist to Watch' } : null

  return {
    title: 'Daily Inspiration',
    subTitle: dateFormat(new Date()).toUpperCase(),
    artistToWatch,
    categories: data?.categories || [],
  }
}

export const getServerSideProps = async () => {
  try {
    const dailyInspiration = (await getDailyInspiration()) as DailyInspiration
    return {
      props: {
        dailyInspiration,
      },
    }
  } catch (error) {
    console.error('Error fetching:', error)
    return { props: { artistToWatch: {}, categories: [] } }
  }
}

type HomepageProps = {
  dailyInspiration: DailyInspiration
}

const Page = (props: HomepageProps) => {
  const { dailyInspiration } = props

  return (
    <PublicLayout isTransparent={true} isSearchHidden={true}>
      <Homepage dailyInspiration={dailyInspiration} />
    </PublicLayout>
  )
}

export default Page
