// local components
import Search from './Search'
// import CommunitySection from './CommunitySection'
import DailyInspirationSection from './DailyInspirationSection'
import FeaturedInSection from './FeaturedInSection'

type ArtistToWatch = {
  id: number
  coverImage: string
  fullName: string
  username: string
  subtitle?: string
}

type InspirationCategory = {
  id: number
  title: string
  subTitle: string
  thumbnail: string
  link: string
}

export type DailyInspiration = {
  title: string
  subTitle: string
  artistToWatch: ArtistToWatch
  categories: Array<InspirationCategory>
}

const Homepage = ({ dailyInspiration }: { dailyInspiration: DailyInspiration }) => {
  return (
    <main className="homepage">
      {/* Search */}
      <Search />

      {/* Cohart community */}
      {/* <CommunitySection /> */}

      {/* Daily Inspiration */}
      <DailyInspirationSection
        title={dailyInspiration?.title || 'Daily Inspiration'}
        subTitle={dailyInspiration?.subTitle}
        artistToWatch={dailyInspiration?.artistToWatch}
        categories={dailyInspiration?.categories}
      />

      {/* featured in */}
      <FeaturedInSection />
    </main>
  )
}

export default Homepage
