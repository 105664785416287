import PropTypes from 'prop-types'
import cls from 'classnames'

type LandingPageSectionProps = {
  className?: string
  children?: any
}

const LandingPageSection = ({ children, className }: LandingPageSectionProps) => {
  return (
    <section className={cls('container mx-auto py-[72px] 2xl:max-w-[1536px] 3xl:max-w-[1940px]', className)}>
      {children}
    </section>
  )
}

LandingPageSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default LandingPageSection
