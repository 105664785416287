// @ts-nocheck

import * as React from 'react'
import { cva } from 'class-variance-authority'
import { cn } from '@shared/lib/utils'

const headingVariants = cva('', {
  variants: {
    size: {
      h1: 'text-[24px] lg:text-[48px]',
      h2: 'text-[36px]',
    },
  },
  defaultVariants: {
    size: 'h1',
  },
})

export interface HeadingProps {
  className?: string
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children?: any
}

const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(({ className, size, element, ...props }, ref) => {
  const Tag: keyof JSX.IntrinsicElements = element ?? size ?? 'h1'
  return <Tag className={cn(headingVariants({ size, className }))} ref={ref} {...props} />
})
Heading.displayName = 'Heading'

export { Heading, headingVariants }
