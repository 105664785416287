import PropTypes from 'prop-types'
import Link from 'next/link'

// @ts-ignore
const Logo = ({ className, isTransparent = false }) => {
  return (
    <div className={className}>
      <Link href="/about-us" className="flex items-center pr-1 lg:pr-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="90" height="49" viewBox="0 0 90 49" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5519 15.2574C19.5519 11.4484 17.8869 9.54395 14.557 9.54395C11.1809 9.54395 9.51562 11.4487 9.51563 15.2574L9.51563 33.6971C9.51563 37.5058 11.1809 39.4106 14.557 39.4106C17.8869 39.4106 19.5519 37.5061 19.5519 33.6971V27.1945H15.991V33.6505C15.991 35.0907 15.5293 35.8342 14.5561 35.8342C13.583 35.8342 13.0766 35.0916 13.0766 33.6505L13.0766 15.304C13.0766 13.8638 13.5838 13.1203 14.5561 13.1203C15.5284 13.1203 15.991 13.8629 15.991 15.304L15.991 21.76H19.5519V15.2574ZM22.1016 15.2574L22.1016 33.6971C22.1016 37.5075 23.7668 39.4106 27.1429 39.4106C30.4723 39.4106 32.137 37.5061 32.137 33.6971V15.2574C32.137 11.4484 30.4723 9.54395 27.1429 9.54395C23.7668 9.54395 22.1016 11.4487 22.1016 15.2574ZM28.5778 33.6505C28.5778 35.0907 28.1135 35.8342 27.1429 35.8342C26.1714 35.8342 25.6591 35.0907 25.6591 33.6505L25.6591 15.304C25.6591 13.8638 26.1706 13.1203 27.1429 13.1203C28.1152 13.1203 28.5778 13.8629 28.5778 15.304L28.5778 33.6505ZM38.5141 9.87988V22.7893H41.1044V9.87988H44.6653L44.6653 39.0921L41.1044 39.0921V26.1792H38.5141V39.0921H34.9531V9.87988L38.5141 9.87988ZM55.3021 9.87988H49.1517L47.0703 39.0887H50.7697L50.9554 35.141H53.4984L53.6832 39.0955H57.3834L55.3021 9.87988ZM52.4349 13.2706L53.4064 31.896H51.0009L51.9724 13.2706H52.4349ZM59.743 9.87988H64.9228C68.1141 9.87988 69.7785 11.6448 69.7785 15.7289V21.0236C69.7785 23.9498 68.9927 25.3468 67.189 25.8554V26.3199H68.4837L70.3803 39.0929H66.7265L65.2916 28.1781L63.3031 28.1781L63.3031 39.0921H59.7422L59.743 9.87988ZM63.304 13.2706V24.7865H64.6915C65.5702 24.7865 66.2175 23.904 66.2175 22.4638V15.5891C66.2175 14.1031 65.5702 13.2672 64.6915 13.2672L63.304 13.2706ZM71.7656 9.87988V13.2706H74.4944V39.0921H78.0553V13.2706H80.784V9.87988H71.7656Z"
            fill={isTransparent ? 'white' : '#1A1A1A'}
          />
        </svg>
      </Link>
    </div>
  )
}

Logo.propTypes = {
  className: PropTypes.string,
  isTransparent: PropTypes.bool,
}

export default Logo
