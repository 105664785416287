import { cn } from '@shared/lib/utils'
import Marquee from '@shared/components/ui/marquee'

type InfiniteSwiperProps = {
  className?: string
  slideContainerClassName?: string
  slideClassName?: string
  slides?: any
  slideClickable?: boolean
}

export const InfiniteSwiper = ({
  className,
  slideContainerClassName,
  slideClassName,
  slides = [],
  slideClickable,
}: InfiniteSwiperProps) => {
  return (
    <div className={cn('relative flex w-full flex-col items-center justify-center overflow-hidden', className)}>
      <Marquee pauseOnHover className={cn('[--duration:20s]', slideContainerClassName)}>
        {slides.map((slide: any, index: number) => (
          <div
            key={index}
            className={cn(slideClassName, {
              'rounded-sm ring-slate-600 ring-offset-4 transition-all ease-in-out hover:ring-2': slideClickable,
            })}
          >
            {slide}
          </div>
        ))}
      </Marquee>
      <div className="pointer-events-none absolute inset-y-0 left-0 bg-gradient-to-r from-white dark:from-background md:w-[10%]"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 bg-gradient-to-l from-white dark:from-background md:w-[10%]"></div>
    </div>
  )
}

export default InfiniteSwiper
