import { useMemo } from 'react'
import PropTypes from 'prop-types'
import cls from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { isServer } from '@tanstack/react-query'
import _ from 'lodash'

// @shared
import { Heading } from '@shared/components/Heading'
import LandingPageSection from '@shared/components/LandingPageSection'
import InfiniteSwiper from '@shared/components/InfiniteSwiper'

const images = [
  { name: 'Vogue', src: '/images/landing-pages/homepage/featured-in/vogue.png', className: 'h-[53px] w-auto ' },
  { name: 'Paper', src: '/images/landing-pages/homepage/featured-in/paper.png', className: 'h-[39px] w-auto ' },
  {
    name: 'Tech Crunch',
    src: '/images/landing-pages/homepage/featured-in/tech-crunch.png',
    className: 'h-[31px] w-auto ',
  },
  {
    name: 'First Classe',
    src: '/images/landing-pages/homepage/featured-in/first-classe.png',
    className: 'h-[56px] w-auto ',
  },
  {
    name: 'Brex',
    src: '/images/landing-pages/homepage/featured-in/brex.png',
    className: 'h-[53px] w-auto ',
  },
  {
    name: 'SF Business Times',
    src: '/images/landing-pages/homepage/featured-in/SF-Business-Times.png',
    className: 'h-[54px] w-auto ',
  },
]

// @ts-ignore
const FeaturedInSection = ({ className }) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const imageChunks = useMemo(() => _.chunk(images, 2), [])

  return (
    <LandingPageSection className={cls(className, '!w-full !max-w-full !px-0')}>
      {/* header text */}
      <Heading className="text-center leading-none" size="h1" element="h2">
        Featured In
      </Heading>

      {/* list featured in */}
      {(isDesktop || isServer) && (
        <InfiniteSwiper
          className="mt-[73px]"
          slideContainerClassName="![--gap:60px]"
          slideClassName="!h-[56px] !flex items-center"
          slides={images.map((image) => (
            <img className={cls('object-cover object-center', image.className)} src={image.src} alt={image.name} />
          ))}
        />
      )}

      {!isDesktop && !isServer && (
        <div className="container mt-[50px] flex flex-col gap-[49px]" suppressHydrationWarning>
          {Array.from({ length: 3 }).map((__, rowIndex) => (
            <div key={rowIndex} className="flex items-center gap-[49px]">
              {imageChunks[rowIndex].map((image, index) => (
                <div key="index" className="w-full">
                  <img className="h-full w-full object-cover object-center" src={image.src} alt={image.name} />
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </LandingPageSection>
  )
}

FeaturedInSection.propTypes = {
  className: PropTypes.string,
}

export default FeaturedInSection
