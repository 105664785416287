import { useState } from 'react'
import { PATTERN } from 'configs/validation'
import { useForm } from 'react-hook-form'

// @shared
import useCustomMutation from 'shared/hooks/useCustomMutation'
import Button from 'shared/components/button/Button'
import InputErrorText from 'shared/components/input/InputErrorText'
import subscribeToNewsletter from 'shared/services/public/subscribeToNewsletter'

const NewsletterForm = () => {
  // -- states --
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')

  // -- mutation --
  const { mutate: subscribeToNewsletterMutation, isLoading: isFormSubmitting } = useCustomMutation(
    subscribeToNewsletter,
    {
      onSuccess: (response: { message?: string }) => {
        setSuccessMessage(
          response?.message ||
            `You're on the list! We'll send you future updates and look forward to keeping in touch!`,
        )
        setIsSubscribed(true)
        setIsError(false)
      },
      onError: () => {
        setIsSubscribed(false)
        setIsError(true)
      },
    },
  )

  // -- form object --
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; firstName?: string }>()

  const handleFormSubmit = async ({ email, firstName }: { email: string; firstName?: string }) => {
    await subscribeToNewsletterMutation({ email, firstName })
  }

  return (
    <div className="relative w-full font-monument-grotes">
      {/* title */}
      <h2 className="mb-2 text-sm uppercase text-white/60">Subscribe to our newsletter</h2>

      {/* error */}
      {!isSubscribed && isError && <p className="text-white">Something went wrong. Please try again later.</p>}

      {/* success */}
      {isSubscribed && !isError && <p className="text-white empty:hidden">{successMessage}</p>}

      {/* form inputs */}
      {!isSubscribed && !isError && (
        <form onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col gap-5">
          <div className="flex flex-col gap-1">
            {/* first name input */}
            <div className="relative flex w-full max-w-[350px] items-center border-b border-white/60 py-2">
              <input
                className="w-full border-none bg-transparent p-0 text-white transition-all placeholder:text-white/60 focus:outline-0 focus:ring-0"
                placeholder="First name"
                {...register('firstName', {
                  maxLength: {
                    value: 30,
                    message: 'First name must be at most 30 characters',
                  },
                })}
              />
            </div>
            {/* first name error */}
            {errors.firstName?.message && <InputErrorText message={errors.firstName?.message} className="!mt-0" />}

            {/* email input */}
            <div className="relative flex w-full max-w-[350px] items-center border-b border-white/60 py-2">
              <input
                className="w-full border-none bg-transparent p-0 text-white transition-all placeholder:text-white/60 focus:outline-0 focus:ring-0"
                placeholder="Email address *"
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Email address is required',
                  },
                  pattern: {
                    value: PATTERN.email,
                    message: 'Email is not valid',
                  },
                })}
              />
            </div>
            {/* email error */}
            {errors.email?.message && <InputErrorText message={errors.email?.message} className="!mt-0" />}
          </div>

          {/* submit button */}
          <div className="inline-block">
            <Button
              name="Subscribe"
              wrapperClass="rounded-full bg-white !px-5 !py-2"
              contentClass="normal-case !text-base"
              loading={isFormSubmitting}
              type="submit"
            />
          </div>
        </form>
      )}
    </div>
  )
}

export default NewsletterForm
