import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import throttle from 'lodash.throttle'
import { useMediaQuery } from 'react-responsive'
import useAuth from 'hooks/authentication/useAuth'
import useScrollDirectionForWindow from 'hooks/useScrollDirectionForWindow'

// local component
import Logo from './Logo'
import LeftNavigation from './LeftNavigation'
import RightNavigation from './RightNavigation'

type HeaderProps = {
  isHidden?: boolean
  isTransparent?: boolean
  isSearchHidden?: boolean
  currentSearchText?: string
  onSearch?: (text: string) => void
}

const Header = ({ isTransparent = false, isSearchHidden = false, currentSearchText, onSearch }: HeaderProps) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const scrollDirectionWindow = useScrollDirectionForWindow()

  // states
  const [isHeaderTransparent, setIsHeaderTransparent] = useState(isTransparent)

  // auth
  const { isLoggedIn, authUser: myProfile } = useAuth()

  // window scroll
  useEffect(() => {
    if (!isTransparent) return
    const eventListener = throttle(() => {
      const stickOnTop = window.scrollY <= 5
      setIsHeaderTransparent(stickOnTop)
    }, 500)
    window.addEventListener('scroll', eventListener)
    return () => window.removeEventListener('scroll', eventListener)
  }, [isTransparent, setIsHeaderTransparent])

  // is scroll down direction
  const isScrollDown = useMemo(() => scrollDirectionWindow === 'down', [scrollDirectionWindow])
  const isHeaderHidden = useMemo(() => {
    if (!isDesktop && isScrollDown) return true
    return false
  }, [isDesktop, isScrollDown])

  return (
    <header
      className={classNames('z-50 h-[80px] w-full transition-all duration-300 ease-in-out', {
        hidden: isHeaderHidden,
        'bg-white shadow': !isHeaderHidden && !isHeaderTransparent,
        'fixed left-0 top-0': !isHeaderHidden,
      })}
    >
      <div className="flex h-20 items-center px-5">
        {/* logo */}
        <Logo isTransparent={isHeaderTransparent} />

        {/* left menu */}
        <LeftNavigation
          isTransparent={isHeaderTransparent}
          isLoggedIn={isLoggedIn}
          isSearchHidden={isSearchHidden}
          currentSearchText={currentSearchText}
          onSearch={onSearch}
        />

        {/* right menu */}
        <RightNavigation isTransparent={isHeaderTransparent} isLoggedIn={isLoggedIn} profile={myProfile} />
      </div>
    </header>
  )
}

Header.propTypes = {
  isTransparent: PropTypes.bool,
  isSearchHidden: PropTypes.bool,
  currentSearchText: PropTypes.string,
  onSearch: PropTypes.func,
}
export default Header
