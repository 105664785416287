import { useMemo } from 'react'
import useFetchingMetadata from 'shared/hooks/data-fetching/useFetchingMetadata'

type CheckRoleResponse = {
  isUserHenry: boolean
  isUserCreator: boolean
  isUserCollector: boolean
  isUserCA: boolean
  isUserIA: boolean
  isFetchingRoleMap: boolean
  isRoleMapReady: boolean
}

/**
 * Custom hook for checking user role.
 *
 * @param userRole - The user role to check.
 * @returns An object containing the results of the role checks.
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useCheckingRole = (userRole: any) => {
  const { isLoading: isFetchingRoleMap } = useFetchingMetadata()

  const checkRoleResponse: CheckRoleResponse = useMemo(() => {
    const isUserHenry = false
    const isUserCreator = true
    const isUserCollector = true
    const isUserCA = true
    const isUserIA = true
    const isRoleMapReady = true

    return {
      isUserHenry,
      isUserCreator,
      isUserCollector,
      isUserCA,
      isUserIA,
      isFetchingRoleMap,
      isRoleMapReady,
    }
  }, [isFetchingRoleMap])

  return checkRoleResponse
}

export default useCheckingRole
