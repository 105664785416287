import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useBoolean } from 'usehooks-ts'
import updateProfile from 'shared/services/me/updateProfile'
import useShowingInfoOnce from 'hooks/useShowingInfoOnce'
import dynamic from 'next/dynamic'
import { notifyPromise, notifySuccess } from 'utils/toast'
import useAuth from 'hooks/authentication/useAuth'
import useCheckingRole from './data-fetching/useCheckingRole'
import { useConfirmModal } from 'hooks/common/useConfirmModal'
import { useRouter } from 'next/router'
import { identifySegmentUser, trackSegmentCustomEvent } from 'shared/services/analytics/segment'

const SegmentationModal = dynamic(() => import('shared/components/modal/SegmentationModal'))
const AreYouArtistModal = dynamic(() => import('shared/components/modal/ChoosingRoleModal/AreYouArtistModal'))
const AreYouCollectorModal = dynamic(() => import('shared/components/modal/ChoosingRoleModal/AreYouCollectorModal'))
const AreYouAdvisorModal = dynamic(() => import('shared/components/modal/ChoosingRoleModal/AreYouAdvisorModal'))
const ApplyToBeCohartAdvisorModal = dynamic(
  () => import('views/advisor-artists/components/ApplyToBeCohartAdvisorModal'),
)
const NewUserGettingStartedModal = dynamic(
  () => import('views/dashboard-homepage/components/NewUserGettingStartedModal'),
  {
    ssr: false,
  },
)

type AskingRoleModalsContextType = {
  showAreYouArtistModal: () => void
  showAreYouCollectorModal: () => void
  showAreYouAdvisorModal: () => void
  showApplyToBeCohartAdvisorModal: () => void
  setIsAskingRoleBannerClosed: (value: boolean) => void
  isAskingRoleBannerClosed: boolean
  setIsApplyCohartAdvisorBannerClosed: (value: boolean) => void
  isApplyCohartAdvisorBannerClosed: boolean
}

const AskingRoleModalsContext = createContext<AskingRoleModalsContextType>({} as AskingRoleModalsContextType)

type AskingRoleModalsProviderProps = {
  children: ReactNode
}

type RoleName = 'Artist' | 'Collector' | 'Advisor' | 'Cohart Advisor' | 'Henry' | ''

export const AskingRoleModalsProvider = ({ children }: AskingRoleModalsProviderProps) => {
  const { authUser, isUserSegmentationPopupVisible, shouldRedirectAfterSegmentation, handleUserSegmentationPopup } =
    useAuth()
  const { isUserCA, isUserIA, isUserCreator, isUserCollector, isUserHenry } = useCheckingRole(authUser?.role)
  const router = useRouter()

  const currentRoleName: RoleName | null = useMemo(() => {
    if (isUserCreator && isUserCreator) return ''
    if (isUserHenry) return 'Henry'
    if (isUserCreator) return 'Artist'
    if (isUserCollector) return 'Collector'
    if (isUserCA) return 'Cohart Advisor'
    if (isUserIA) return 'Advisor'
    return null // if the user's role is not fetched yet
  }, [isUserCA, isUserCollector, isUserCreator, isUserHenry, isUserIA])

  const {
    value: isAreYouArtistModalOpen,
    setTrue: showAreYouArtistModal,
    setFalse: hideAreYouArtistModal,
  } = useBoolean(false)

  const {
    value: isAreYouCollectorModalOpen,
    setTrue: showAreYouCollectorModal,
    setFalse: hideAreYouCollectorModal,
  } = useBoolean(false)

  const {
    value: isAreYouAdvisorModalOpen,
    setTrue: showAreYouAdvisorModal,
    setFalse: hideAreYouAdvisorModal,
  } = useBoolean(false)

  const {
    value: isApplyToBeCohartAdvisorModalOpen,
    setTrue: showApplyToBeCohartAdvisorModal,
    setFalse: hideApplyToBeCohartAdvisorModal,
  } = useBoolean(false)

  const [isAskingRoleBannerClosed, setIsAskingRoleBannerClosed] = useShowingInfoOnce('profileAskingRoleBanner')
  const [isApplyCohartAdvisorBannerClosed, setIsApplyCohartAdvisorBannerClosed] =
    useShowingInfoOnce('applyToBeCohartAdvisorBanner')

  const toBeCreator = useCallback(async () => {
    await notifyPromise(
      async () => {
        await updateProfile({ role: 'creator' })

        trackSegmentCustomEvent('user_changed_role', { user_role: 'creator' })
        if (authUser?.id) identifySegmentUser(authUser.id.toString(), { user_role: 'creator' })

        hideAreYouArtistModal()
        setIsAskingRoleBannerClosed(true) // after user choose an option, we don't show the banner again
        if (shouldRedirectAfterSegmentation) router.push('/artist/summary') // redirect to the artist dashboard
      },
      {
        pending: 'Updating your role...',
        success: 'You are now an artist!',
      },
    )
  }, [hideAreYouArtistModal, router, setIsAskingRoleBannerClosed, shouldRedirectAfterSegmentation, authUser])

  const toBeIndependentAdvisor = useCallback(async () => {
    await notifyPromise(
      async () => {
        await updateProfile({ role: 'independent-advisor' })

        trackSegmentCustomEvent('user_changed_role', { user_role: 'independent-advisor' })
        if (authUser?.id) identifySegmentUser(authUser.id.toString(), { user_role: 'independent-advisor' })

        hideAreYouAdvisorModal()
        setIsAskingRoleBannerClosed(true) // after user choose an option, we don't show the banner again
        router.push('/homepage') // redirect to the personalized homepage
      },
      {
        pending: 'Updating your role...',
        success: 'You are now an independent advisor!',
      },
    )
  }, [hideAreYouAdvisorModal, setIsAskingRoleBannerClosed, router, authUser])

  const toBeCollector = useCallback(async () => {
    await notifyPromise(
      async () => {
        await updateProfile({ role: 'collector' })

        trackSegmentCustomEvent('user_changed_role', { user_role: 'collector' })
        if (authUser?.id) identifySegmentUser(authUser.id.toString(), { user_role: 'collector' })

        hideAreYouCollectorModal()
        setIsAskingRoleBannerClosed(true) // after user choose an option, we don't show the banner again
        router.push('/collector/overview') // redirect to the artist dashboard
      },
      {
        pending: 'Updating your role...',
        success: 'You are now a buyer!',
      },
    )
  }, [authUser, hideAreYouCollectorModal, setIsAskingRoleBannerClosed, router])

  const showConfirmModal = useConfirmModal()

  const makeConfirmToChangeRole = useCallback(
    (onConfirm: () => unknown) => () => {
      if (currentRoleName === 'Henry') {
        // no need to show confirm modal if the user is a Henry user
        onConfirm()
        return
      }

      showConfirmModal(
        {
          title: 'Change your account type?',
          content: (
            <div className="space-y-4 text-[14px] leading-[1.2] text-kokushoku-black">
              <p className="font-semibold">You're already registered as an {currentRoleName}.</p>
              <>
                <p>
                  If you change your account type, it will permanently delete all your {currentRoleName}-related
                  contents.
                </p>
                <p>This effect will be immediate.</p>
              </>
              <p>Do you understand the risks and wish to proceed?</p>
            </div>
          ),
          description: '',
          cancelBtnText: 'Cancel',
          confirmBtnText: 'I understand',
        },
        { onConfirm },
      )
    },
    [showConfirmModal, currentRoleName],
  )

  const closeSegmentationModal = useCallback(() => {
    handleUserSegmentationPopup(false)
  }, [handleUserSegmentationPopup])

  const handleChoosingSell = useCallback(() => {
    closeSegmentationModal()
    makeConfirmToChangeRole(toBeCreator)()
  }, [closeSegmentationModal, makeConfirmToChangeRole, toBeCreator])

  const handleChoosingBuy = useCallback(() => {
    closeSegmentationModal()
    notifySuccess('Click Discover to get inspired.')
  }, [closeSegmentationModal])

  const contextValue = useMemo(() => {
    return {
      showAreYouArtistModal,
      showAreYouAdvisorModal,
      showAreYouCollectorModal,
      showApplyToBeCohartAdvisorModal,
      setIsAskingRoleBannerClosed,
      isAskingRoleBannerClosed,
      setIsApplyCohartAdvisorBannerClosed,
      isApplyCohartAdvisorBannerClosed,
    }
  }, [
    isApplyCohartAdvisorBannerClosed,
    isAskingRoleBannerClosed,
    setIsApplyCohartAdvisorBannerClosed,
    setIsAskingRoleBannerClosed,
    showApplyToBeCohartAdvisorModal,
    showAreYouAdvisorModal,
    showAreYouArtistModal,
    showAreYouCollectorModal,
  ])

  const [gettingStartedModalOpened, setGettingStartedModalOpened] = useState(false)
  useEffect(() => {
    try {
      if (authUser && !authUser?.flags?.hasOpenedSegmentationPopup) {
        setGettingStartedModalOpened(true)
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, [authUser])

  return (
    <AskingRoleModalsContext.Provider value={contextValue}>
      {children}
      {/* to be Artist */}
      {isAreYouArtistModalOpen && (
        <AreYouArtistModal onClose={hideAreYouArtistModal} onChoosingOption={makeConfirmToChangeRole(toBeCreator)} />
      )}

      {/* to be Collector */}
      {isAreYouCollectorModalOpen && (
        <AreYouCollectorModal
          onChoosingOption={makeConfirmToChangeRole(toBeCollector)}
          onClose={hideAreYouCollectorModal}
        />
      )}

      {/* to be IA */}
      {isAreYouAdvisorModalOpen && (
        <AreYouAdvisorModal
          onClose={hideAreYouAdvisorModal}
          onApply={makeConfirmToChangeRole(toBeIndependentAdvisor)}
        />
      )}

      {/* to be CA */}
      {isApplyToBeCohartAdvisorModalOpen && <ApplyToBeCohartAdvisorModal onClose={hideApplyToBeCohartAdvisorModal} />}

      {/* User Segmentation Modal */}
      {isUserHenry && isUserSegmentationPopupVisible && (
        <SegmentationModal
          onClose={closeSegmentationModal}
          onChoosingBuy={handleChoosingBuy}
          onChoosingSell={handleChoosingSell}
        />
      )}

      {gettingStartedModalOpened && (
        <NewUserGettingStartedModal
          user={authUser}
          withPurchaseButton={false}
          // @ts-ignore
          onClose={() => setGettingStartedModalOpened(false)}
        />
      )}
    </AskingRoleModalsContext.Provider>
  )
}

export const useAskingRoleModals = () => {
  const context = useContext(AskingRoleModalsContext)
  return context
}
