// @ts-nocheck

import * as React from 'react'
import { cva } from 'class-variance-authority'
import { cn } from '@shared/lib/utils'

const textVariants = cva('text-kokushoku-black leading-tight', {
  variants: {
    size: {
      sm: 'text-[12px]',
      md: 'text-[14px]',
      lg: 'text-[16px]',
      xl: 'text-[20px]',
      '2xl': 'text-[24px]',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

export interface TextProps {
  className?: string
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  children?: any
}

const Text = React.forwardRef<HTMLParagraphElement, TextProps>(({ className, size, ...props }, ref) => {
  const Tag: keyof JSX.IntrinsicElements = 'p'
  return <Tag className={cn(textVariants({ size, className }))} ref={ref} {...props} />
})
Text.displayName = 'Text'

export { Text, textVariants }
